(function($){
	if($('.tiny-1_element').length ) {
		var slider = tns({
			container: '.tiny-1_element',
			items: 1,
			controls: false,
			mouseDrag: true,
			nav: false,
			navPosition: 'bottom',
			autoplay: false,
			autoplayTimeout: 5000,
			autoplayHoverPause:true,
		    autoplayButtonOutput:false,
			mode : 'gallery',
		});
		};
		if($('.tiny-1_element_buttons').length ) {
			var slider = tns({
				container: '.tiny-1_element_buttons',
				items: 1,
				controls: false,
				mouseDrag: true,
				nav: true,
				navPosition: 'bottom',
				autoplay: true,
				autoplayTimeout: 5000,
				autoplayHoverPause:true,
				autoplayButtonOutput:false,
				mode : 'gallery',
			});
			};
		
		if($('.tiny_2_elements').length ) {
		var slider = tns({
			container: '.tiny_2_elements',
			items: 1,
			controls: false,
			mouseDrag: true,
			nav: true,
			navPosition: 'bottom',
			animateIn: 'jello',
			animateOut: 'rollOut',
			speed: 1000,
			swipeAngle: false,
			responsive: {
				640: {
				  gutter: 20,
				  items: 1
				},
				700: {
				  gutter: 30,
				  items: 2
				}
			  }
		});
	};

	if($('.tiny-3_elements').length ) {
		var slider = tns({
			container: '.tiny-3_elements',
			items: 1,
			controls: false,
			mouseDrag: true,
			nav: true,
			navPosition: 'bottom',
			responsive: {
				640: {
				  gutter: 20,
				  items: 2
				},
				700: {
				  gutter: 30
				},
				1024: {
				  items: 3
				}
			  }
		});
	};
	
	if($('.tiny-4_elements').length ) {
		var slider = tns({
			container: '.tiny-4_elements',
			items: 1,
			controls: false,
			mouseDrag: true,
			nav: true,
			navPosition: 'bottom',
			loop: false,
			responsive: {
				640: {
				  gutter: 20,
				  items: 3
				},
				700: {
				  gutter: 30,
				  items: 4
				}
			  }
		});
	};
	if($('.tiny-8_elements').length ) {
		var slider = tns({
			container: '.tiny-8_elements',
			items: 3,
			gutter: 20,
			controls: false,
			mouseDrag: true,
			nav: false,
			navPosition: 'bottom',
			autoplay: true,
			autoplayTimeout: 3500,
			autoplayHoverPause:true,
			autoplayButtonOutput:false,
			loop: false,
			responsive: {
				700: {
				  gutter: 30,
				  items: 4
				},
				900: {
					gutter: 30,
					items: 8
				  }
			  }
		});
	};
	if($('.content-tab').length) {

		 $(".content-tab:not(:eq(0))").toggle();
		
		$(".fa-minus").hide();
		$(".fa-plus:eq(0)").hide();

		$(".fa-minus:eq(0)").show();

		$(".title-tab").click(function(){
			$(".content-tab").hide();
			$(".fa-minus").hide();
			$(".fa-plus").show();
			$(this).next().show();
			$(this).children(".fa-plus").hide();				

			$(this).children(".fa-minus").show();				
		})

	};

	if($('.anchor').length) {

		function scrollToAnchor(aid){
			var aTag = $('#'+ aid);
			$('html,body').animate({scrollTop: aTag.offset().top},'slow');
		}
		
		$(".anchor").click(function(e) {
			e.preventDefault();
			var aid = $(this).attr('href');
			console.log(aid);
		scrollToAnchor(aid);

		})	;
	}

	if($('.js-select').length ) {

		// Iterate over each select element
		$('.js-select').each(function () {

			// Cache the number of options
			var $this = $(this),
				numberOfOptions = $(this).children('option').length;

			// Hides the select element
			$this.addClass('s-hidden');

			// Wrap the select element in a div
			$this.wrap('<div class="select"></div>');

			// Insert a styled div to sit over the top of the hidden select element
			$this.after('<div class="styledSelect"></div>');

			// Cache the styled div
			var $styledSelect = $this.next('div.styledSelect');

			// Show the first select option in the styled div
			$styledSelect.text($this.children('option').eq(0).text());

			// Insert an unordered list after the styled div and also cache the list
			var $list = $('<ul />', {
				'class': 'options'
			}).insertAfter($styledSelect);

			// Insert a list item into the unordered list for each select option
			for (var i = 0; i < numberOfOptions; i++) {
				$('<li />', {
					text: $this.children('option').eq(i).text(),
					rel: $this.children('option').eq(i).val()
				}).appendTo($list);
			}

			// Cache the list items
			var $listItems = $list.children('li');

			// Show the unordered list when the styled div is clicked (also hides it if the div is clicked again)
			$styledSelect.click(function (e) {
				e.stopPropagation();
				if($(this).hasClass('active')) {
					console.log($(this));
				$(this).removeClass('active').next('ul.options').slideUp();
				} else {
					$('div.styledSelect.active').each(function () {
						$(this).removeClass('active').next('ul.options').slideUp();
					});
				$(this).toggleClass('active').next('ul.options').slideToggle();
				}
			});

			// Hides the unordered list when a list item is clicked and updates the styled div to show the selected list item
			// Updates the select element to have the value of the equivalent option
			$listItems.click(function (e) {
				e.stopPropagation();
				$styledSelect.text($(this).text()).removeClass('active');
				$this.val($(this).attr('rel'));
				$list.slideUp();
				SubmitForm($this.data('form-id'));
				/* alert($this.val()); Uncomment this for demonstration! */
			});

			// slideUps the unordered list when clicking outside of it
			$(document).click(function () {
				$styledSelect.removeClass('active');
				$list.slideUp();
			});


			function SubmitForm(formId) {
				var oForm = document.getElementById(formId);
				formUrl = $(oForm).data('url');
				if(formId == 'clinic-filter') {
					clinic =  $(oForm).data('clinic');
					window.location.href= formUrl + '/?especialidad=' + document.getElementById('specialty_select').value + '&clinica=' + clinic;
					
				} else {
					if (oForm) {
						var specialty = document.getElementById('specialty_select').value;
						if(document.getElementById('clinic_select')) {
							var clinic = document.getElementById('clinic_select').value;
						} else {
							var clinic = '';
						}
						window.location.href=  '?especialidad=' + specialty + '&clinica=' + clinic;
					}
					else {
						console.log("DEBUG - could not find element " + formId);
					}
				}
			}
		});
	

	}
	$('.menu-item-has-children').on('click', function(){
		$(this).find(".sub-menu").toggleClass('sub-menu-active');
	});
	
})(jQuery);


const actualBtn = document.getElementById('actual-btn');

const fileChosen = document.getElementById('file-chosen');
if(actualBtn) {
	actualBtn.addEventListener('change', function(){
		console.log(this.files[0]);
	  fileChosen.textContent = this.files[0].name
	})
}

(function($) {
if($('.acf-map').length) {

	/**
	 * initMap
	 *
	 * Renders a Google Map onto the selected jQuery element
	 *
	 * @date    22/10/19
	 * @since   5.8.6
	 *
	 * @param   jQuery $el The jQuery element.
	 * @return  object The map instance.
	 */
	function initMap($el) {

		// Find marker elements within map.
		var $markers = $el.find('.marker');

		// Create gerenic map.
		var mapArgs = {
			zoom: 18 || $el.data('zoom'),
			mapTypeId: google.maps.MapTypeId.ROADMAP
		};
		var map = new google.maps.Map($el[0], mapArgs);

		// Add markers.
		map.markers = [];
		$markers.each(function() {
			initMarker($(this), map);
		});

		// Center map based on markers.
		centerMap(map);

		// Return map instance.
		return map;
	}

	/**
	 * initMarker
	 *
	 * Creates a marker for the given jQuery element and map.
	 *
	 * @date    22/10/19
	 * @since   5.8.6
	 *
	 * @param   jQuery $el The jQuery element.
	 * @param   object The map instance.
	 * @return  object The marker instance.
	 */
	var currWindow =false; 
	function initMarker($marker, map) {

		// Get position from marker.
		var lat = $marker.data('lat');
		var lng = $marker.data('lng');
		var title = $marker.data('title');

		var latLng = {
			lat: parseFloat(lat),
			lng: parseFloat(lng)
		};
		const icon =
		"/wp-content/uploads/2023/03/marker.svg";
		// Create marker instance.
		var marker = new google.maps.Marker({
			position: latLng,
			map: map,
			icon: icon,
			title: title

		});

		// Append to reference for later use.
		map.markers.push(marker);

		// If marker contains HTML, add it to an infoWindow.
		if ($marker.html()) {

			// Create info window.
			var infowindow = new google.maps.InfoWindow({
				content: $marker.html()
			});

			// Show info window when marker is clicked.
			google.maps.event.addListener(marker, 'click', function() {
				if( currWindow ) {
					console.log(currWindow);
					currWindow.close();
				 }
		 
				 currWindow = infowindow;
				infowindow.open(map, marker);
				setTimeout(function() {
					$('body').find('.gm-style-iw').css('width', '200px');
				}, 100)
			});

		}
	}

	/**
	 * centerMap
	 *
	 * Centers the map showing all markers in view.
	 *
	 * @date    22/10/19
	 * @since   5.8.6
	 *
	 * @param   object The map instance.
	 * @return  void
	 */
	function centerMap(map) {

		// Create map boundaries from all map markers.
		var bounds = new google.maps.LatLngBounds();
		map.markers.forEach(function(marker) {
			bounds.extend({
				lat: marker.position.lat(),
				lng: marker.position.lng()
			});
		});

		// Case: Single marker.
		if (map.markers.length == 1) {
			map.setCenter(bounds.getCenter());

			// Case: Multiple markers.
		} else {
			map.fitBounds(bounds);
		}
			$('.map-link').on('click', function(e){
				    // get link data-title
					var titleToFind = $(this).data('title');

					// find map marker in markers array with the same title
					var markerToClick;
					for(var i = 0; i < map.markers.length; i++) {
						if(map.markers[i].title === titleToFind) {
						  markerToClick = map.markers[i];
						}
					}
				// and click it using google events API
				new google.maps.event.trigger( markerToClick, 'click' );
			});
	}
	// Render maps on page load.
	$(document).ready(function() {
		$('.acf-map').each(function() {
			var map = initMap($(this));
		});
	});
	$(document).ready(function() {
		$('.js-example-basic-single').select2({
			placeholder: object_name.some_string,
	});
	$('.js-example-basic-single').change(function(){
		$('#clinic-filter').submit();
	});
	});
}
})(jQuery);